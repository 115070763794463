<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    />
    <b10-page-content>
      <b10-view-summary
        :item="formattedItem"
        :showing.sync="showingDialogs.moreInfo"
        :loading-data="loadingData"
        :show-alerts="!routeFromBrowserHistory"
        @click-row="clickSummaryRow"
      >
        <template slot="extraSubtitle">
          <extra-subtitle
            :es-contacto-sistema="formattedItem.esContactoSistema"
          />
        </template>
        <template
          slot="cardActionsLeft"
        >
          <v-btn
            v-if="formattedItem.telefono"
            icon
            color="primary"
            @click.stop="clickLlamar"
          >
            <v-icon>{{ $vuetify.icons.values.phone }}</v-icon>
          </v-btn>
          <v-btn
            v-if="formattedItem.email"
            icon
            color="primary"
            @click.stop="clickEnviarEmail"
          >
            <v-icon>{{ $vuetify.icons.values.email }}</v-icon>
          </v-btn>
        </template>
      </b10-view-summary>
      <b10-fab-button
        v-if="hasEditPerm($route.meta.permission.idobjeto)"
        :icon="$vuetify.icons.values.edit"
        @click="clickEdit"
      />
    </b10-page-content>
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import { viewPageMixin } from '@/mixins/viewPageMixin'
import Data from './ClienteTelefonoViewData'
import { nonEmpty } from '@/utils/templates'
import ExtraSubtitle from '../components/ExtraSubtitle'


export default {
  components: {
    ExtraSubtitle
  },
  mixins: [viewPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  props: {
    editRoute: {
      type: String,
      default: ''
    },
  },
  data () {
    return {
      moreInfoRows: [],
      showingDialogs: {
        moreInfo: false,
      },
    }
  },
  computed: {
    formattedItem () {
      if (!_.isEmpty(this.item.dataset)) {
        const item = _.cloneDeep(this.item.dataset)
        item.id = item.idcliente_telefono
        item.title = item.ttelefono_descripcion
        item.subtitle = this.$options.filters.linebreaksBr(nonEmpty`
          ${item.telefono}
          ${item.email}
        `)
        item.moreInfo = this.buildMoreInfo(this.item.metadata)
        item.esContactoSistema = !!item.idsistema_telefono
        return item
      } else {
        return {}
      }
    },
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      await this.loadItem()
      this.title = (
        this.item.dataset.nombre ||
        this.item.dataset.telefono ||
        this.item.dataset.email
      )
      // asignar moreInfoRows en loadPage() para tener accesibles los permisos del store (no disponibles en data())
      this.moreInfoRows = [
        'nombre',
        'ttelefono_descripcion',
        'email',
        'telefono',
        'horario',
        {
          name: 'email_notifica_sat',
          filter: this.$options.filters.humanizeBoolean,
        },
        {
          name: 'email_notifica_comercial',
          filter: this.$options.filters.humanizeBoolean,
        },
        {
          name: 'email_notifica_admon',
          filter: this.$options.filters.humanizeBoolean,
        },
        {
          name: 'es_responsable_propiedad',
          filter: this.$options.filters.humanizeBoolean,
          visible: !!this.item.dataset.idsistema_telefono,
        },
        {
          name: 'orden_cra',
          visible: !!this.item.dataset.idsistema_telefono,
        },
        {
          name: 'codigo_cra',
          visible: (
            this.hasViewPerm(this.permissions.sistemas.verPersonasContactoCodigoCRA) &&
            !!this.item.dataset.idsistema_telefono
          ),
          password: true,
          clickable: true,
          icon: this.$vuetify.icons.values.show,
        },
        {
          name: 'codigo_coaccion_cra',
          visible: (
            this.hasViewPerm(this.permissions.sistemas.verPersonasContactoCodigoCoaccionCRA) &&
            !!this.item.dataset.idsistema_telefono
          ),
          password: true,
          clickable: true,
          icon: this.$vuetify.icons.values.show,
        },
        'observaciones',
        {
          name: 'para_todos_los_sistemas',
          filter: this.$options.filters.humanizeBoolean,
        },
      ]
    },
    async loadItem () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectClienteTelefono(
          this, this.routeParams.idcliente_telefono, this.routeParams.idsistema
        )
        if (dataset) {
          this.$set(this.item, 'dataset', dataset)
          this.$set(this.item, 'metadata', metadata)
        } else {
          this.itemNotFound()
        }
      } finally {
        this.loadingData = false
      }
    },
    clickEdit () {
      this.$appRouter.push({
        name: this.editRoute,
        params: {
          idcliente_telefono: this.routeParams.idcliente_telefono,
          idcliente: this.routeParams.idcliente,
          idsistema: this.routeParams.idsistema,
          idcliente_potencial: this.routeParams.idcliente_potencial,
        },
      })
    },
    clickLlamar () {
      window.open(`tel:${this.item.dataset.telefono}`, '_blank')
    },
    clickEnviarEmail () {
      window.open(`mailto:${this.item.dataset.email}`, '_blank')
    },
    async clickSummaryRow (row) {
      if (row.icon === this.$vuetify.icons.values.show) {
        this.$loading.showManual('Auditando acceso...')
        try {
          await this.$online.auditoria.auditarViewRecordClienteTelefono(this.routeParams.idcliente_telefono)
          if (this.item.dataset.idsistema_telefono) {
            await this.$online.auditoria.auditarViewRecordSistemaTelefono(this.item.dataset.idsistema_telefono)
          }
        } finally {
          this.$loading.hide()
        }
        this.setMoreInfoRowValue(row.name, 'password', false)
        this.setMoreInfoRowValue(row.name, 'icon', this.$vuetify.icons.values.hide)
      } else {
        this.setMoreInfoRowValue(row.name, 'password', true)
        this.setMoreInfoRowValue(row.name, 'icon', this.$vuetify.icons.values.show)
      }
    },
  },
}
</script>
