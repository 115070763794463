import { APIFilter } from '@/utils/api'

export default {
  async selectClienteTelefono (Vue, idclienteTelefono, idsistema) {
    const esContactoDeSistema = (
      !!idsistema &&
      (await Vue.$online.sistemaTelefono.esContactoDeSistema(idclienteTelefono, idsistema))
    )
    if (esContactoDeSistema) {
      const apiFilter = new APIFilter()
      apiFilter.addExact('idcliente_telefono', idclienteTelefono)
      apiFilter.addExact('idsistema', idsistema)
      const resp = await Vue.$api.call('sistemaTelefono.select', { filter: apiFilter })
      return [resp.data.result.dataset[0], resp.data.result.metadata]
    } else {
      const apiFilter = new APIFilter()
      apiFilter.addExact('idcliente_telefono', idclienteTelefono)
      const resp = await Vue.$api.call('clienteTelefono.select', { filter: apiFilter })
      return [resp.data.result.dataset[0], resp.data.result.metadata]
    }
  },
}
